import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactCodeInput from "react-code-input";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "./Styles.css";
import { Button, Grid } from "@material-ui/core";

const kinoTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#f8f8f8",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldModified: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  textFieldSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "600",
    textAlign: "center",
    color: "var(--darkColor)",
    lineHeight: "1.2",
    marginBottom: "1.2rem",
    marginTop: "0rem",
  },
  titleSm: {
    fontSize: "14px",
    textAlign: "center",
    color: "var(--darkColor)",
    width: "100%",
    margin: "auto",
    fontWeight: "400",
    lineHeight: "1.429",
    letterSpacing: "0.2px",
    padding: "0 20px",
  },
  button: {
    backgroundColor: "var(--darkColor)",
    color: "var(--grey)",
    boxShadow: "none",
    marginTop: "8px",
    "&:hover": {
      backgroundColor: "var(--darkColor)",
      boxShadow: "none",
    },
  },
  link: {
    width: "100%",
    textAlign: "center",
    color: "#032836",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: "bold",
  },
  formError: {
    fontSize: "14px",
    color: "red",
    textAlign: "center",
    "&::before": {
      content: "⚠ ",
    },
  },
  sign: {
    color: "var(--darkColor)",
    fontSize: "1rem",
    fontWeight: 400,
  },
  mainError: {
    textAlign: "center",
    color: "red",
    fontWeight: "400",
  },
  verifyEmailForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  noEmailReceivedContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "8px",
  },
  inputs: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  dialogTitle: {
    marginTop: "1.2rem",
    marginBottom: "1.2rem",
    opacity: "0.85",
  },
  statusMsg: {
    padding: "10px",
    marginTop: "0",
    margin: "10px",
    backgroundColor: "#28a745",
    color: "#fff",
    borderRadius: "8px",
    textAlign: "center",
  },
  error: {
    backgroundColor: "#ff726f !important",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
  },
  resendCode: {
    marginTop: "1rem",
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "14px",
  },
  btnSection: {
    justifyContent: "flex-end",
    display: "flex",
    padding: " 1rem",
  },
  btnContainer: {
    backgroundColor: "rgb(248 250 252/1)",
  },
  verifyBtn: {
    backgroundColor: "#ff4746",
    color: "#FFF",
    opacity: "0.6",
    padding: "0.4rem 1.5rem",
  },
}));

const VerifyEmail = ({ ...rest }) => {
  const classes = useStyles();
  const [codeValue, setCodeValue] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [wentWrong, setWentWrong] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sendCodeEnabled, setSendCodeEnabled] = useState(true);

  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleVerificationResend = () => {
    setSendCodeEnabled(false)
    setTimeout(() => {
      setSendCodeEnabled(true)
    }, 60000);
    axios
      .post("/email/resend", {
        email: rest.verifyEmail,
        config,
      })
      .then(
        (response) => {
          setWentWrong(false);
          setStatusMsg("New verification code has been sent");
          setDisabled(false);
        },
        (error) => {
          console.error(error.response.data);
          if (error.response.data) {
            setWentWrong(true);
            setStatusMsg(error.response.data.message);
          }
        }
      );
  };

  const handleChange = (e) => {
    let value = e;
    setErrorMsg("");
    setCodeValue(value);
  };

  useEffect(() => {
    const handleVerification = () => {
      setDisabled(true);
      setWentWrong(false);
      setStatusMsg("");
      if (!codeValue) {
        setErrorMsg("Verification code is required");
        setWentWrong(false);
        return;
      }
      axios
        .post(
          "email/verify/code",
          {
            email: rest.verifyEmail,
            code: codeValue,
          },
          config
        )
        .then(
          (response) => {
            // console.log(response.data);
            if (response.data) {
              if (window.gtag_signup_conversion) {
                window.gtag_signup_conversion("email");
              }
              window.location.href = "/profile";
              setWentWrong(false);
              return;
            }
          },
          (error) => {
            console.error(error.response.data.code[0]);
            setWentWrong(false);
            if (error.response.data.code[0]) {
              setErrorMsg(error.response.data.code[0]);
            }
            setDisabled(false);
          }
        );
      setStatusMsg();
    };

    if (codeValue.length >= 6) {
      handleVerification();
    } else {
      setDisabled(false);
    }
  }, [codeValue]);

  return (
    <div>
      <ThemeProvider theme={kinoTheme}>
        <Dialog
          disableBackdropClick
          open={rest.verifyModalOpen}
          aria-labelledby="verify-modal"
          fullWidth={true}
        >
          <DialogContent style={{ padding: "0 1.5rem" }}>
            <Grid className={classes.dialogTitle}>
              <Grid>
                <p className={classes.title}>Verify email address</p>
              </Grid>
              <Grid>
                <p className={classes.titleSm}>
                    Enter the 6-digit verification code sent to the email address
                </p>
              </Grid>
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                {rest.verifyEmail}
              </p>
            </Grid>
            {statusMsg ? (
              <div
                className={`${classes.statusMsg} ${
                  wentWrong ? classes.error : ""
                }`}
              >
                {statusMsg}
              </div>
            ) : (
              ""
            )}
            <form className={classes.verifyEmailForm} id="verifyEmailForm">
              <div className={classes.inputs} style={{ padding: "0 35px" }}>
                <ReactCodeInput
                  type="number"
                  fields={6}
                  onChange={handleChange}
                  value={codeValue}
                  disabled={disabled}
                  pattern="[0-9]"
                  placeholder="['*', '*', '*', '*','*','*']"
                />
              </div>
              {errorMsg ? (
                <p className={classes.formError}>
                  {"⚠ "}
                  {errorMsg}
                </p>
              ) : (
                ""
              )}
              {sendCodeEnabled ? (
                <p className={classes.resendCode}>
                  Didn't receive the code? Click{" "}
                  <span
                    className={classes.link}
                    onClick={handleVerificationResend}
                  >
                    here{" "}
                  </span>
                  to send a new one.
                </p>
              ) : (
                <p className={classes.resendCode}>
                  Email verification code sent to <span style={{fontWeight: "700"}}>{rest.verifyEmail}</span>, please check your inbox.
                </p>
              )}
            </form>
          </DialogContent>
          <div className={classes.btnContainer}>
            <Grid className={classes.btnSection}>
              <Button
                variant="contained"
                style={{ marginLeft: "1rem", background: "red !important" }}
                className={classes.verifyBtn + " disabled"}
                onClick={handleChange}
              >
                Verify
              </Button>
            </Grid>
          </div>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default VerifyEmail;
